import { ApolloQueryResult, gql, useApolloClient } from "@apollo/client";
import ArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  FormControl,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import localized from "../../../../../../en.json";
import {
  ConfigurationObject,
  FetchConfigurationsByProtocolIds,
} from "../../../../../../Models/models";
import ShowSnackbar from "../../../../../CustomizedSnackbar/ShowSnackbar";
import CommunicationProtocolsTableView from "./CommunicationProtocolsTableView";

interface PropTypes {
  setIsDeviceConfigUpdated: Function;
}

export const GET_ALL_PROTOCOLS = gql`
  query {
    getAllCommunicationProtocols {
      id
      name
    }
  }
`;

export const GET_CONFIGURATIONS_BY_PROTOCOL_IDS = gql`
  query ($communicationProtocolIDs: [Int]!, $deviceID: ID!) {
    getConfigurationsByCommunicationProtocolIdsAndDeviceId(
      communicationProtocolIDs: $communicationProtocolIDs
      deviceID: $deviceID
    ) {
      id
      state
      status
      errorMessage
      parameters {
        endpointUrl
        username
        name
      }
      communicationProtocol {
        id
        name
      }
    }
  }
`;

const CommunicationProtocolTab = (props: PropTypes) => {
  const [isRefetchConfigsRequired, setIsRefetchConfigsRequired] =
    useState<boolean>(false);
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedProtocolList, setSelectedProtocolList] = useState<number[]>(
    []
  );
  const [configurationData, setConfigurationData] = useState<
    ConfigurationObject[]
  >([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const { siteId } = useParams();
  interface ProtocolModel {
    id: number;
    name: string;
  }
  const [protocols, setProtocols] = useState<ProtocolModel[]>([]);
  const open = Boolean(anchorEl);
  const intervalRef = useRef<number | null>(null);

  const getAllProtocols = () => {
    client
      .query({
        query: GET_ALL_PROTOCOLS,
        fetchPolicy: "no-cache",
      })
      .then((response: any) => {
        setProtocols(response.data.getAllCommunicationProtocols);
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-fetch-protocols"],
          false,
          enqueueSnackbar
        );
      });
  };

  const refreshData = () => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = window.setInterval(() => {
      fetchTableData(selectedProtocolList);
    }, 10000);
  };

  useEffect(() => {
    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (selectedProtocolList.length > 0) {
      refreshData();
    } else {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    }
  }, [selectedProtocolList]);

  useEffect(() => {
    if (isRefetchConfigsRequired) {
      fetchTableData(selectedProtocolList);
    }
  }, [isRefetchConfigsRequired]);
  useEffect(() => {
    getAllProtocols();
  }, []);
  const handleChange = (
    event: SelectChangeEvent<typeof selectedProtocolList>
  ) => {
    const {
      target: { value },
    } = event;

    const selectedIds =
      typeof value === "string" ? value.split(",").map(Number) : value;
    setSelectedProtocolList(selectedIds);
    fetchTableData(selectedIds);
  };
  const fetchTableData = async (selectedIds: number[]) => {
    client
      .query({
        query: GET_CONFIGURATIONS_BY_PROTOCOL_IDS,
        variables: {
          communicationProtocolIDs: selectedIds,
          deviceID: deviceId,
        },
        fetchPolicy: "no-cache",
      })
      .then((response: ApolloQueryResult<FetchConfigurationsByProtocolIds>) => {
        const configurationData =
          response.data.getConfigurationsByCommunicationProtocolIdsAndDeviceId;
        setConfigurationData(configurationData);
        setIsRefetchConfigsRequired(false);
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-fetch-configurations"],
          false,
          enqueueSnackbar
        );
      });
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleCreateClick = (protocolId: number, protocolName: string) => {
    setAnchorEl(null);
    navigate(
      `/engineering/site/${siteId}/edgedevices/device/${deviceId}/newprotocol/${protocolId}`,
      {
        state: protocolName,
      }
    );
  };

  return (
    <>
      <Stack
        sx={{
          gap: "24px",
          height: "auto",
          minHeight: "calc(100vh - 100px)",
          overflowY: "auto",
          width: "98%",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            flexWrap: "wrap",
            alignItems: "flex-start",
            marginTop: "24px",
            gap: "16px",
            maxWidth: "calc(100vw - 814px)",
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <FormControl sx={{ width: 350 }}>
              <Select
                multiple
                sx={{
                  font: "16px SiemensSans, Arial",
                  fontWeight: "400",
                  lineHeight: "22px",
                  "& .MuiInputBase-input": {
                    height: "9px",
                    padding: "9px 8px",
                  },
                  "& .MuiSelect-select": {
                    paddingRight: "24px",
                  },
                }}
                displayEmpty
                value={selectedProtocolList}
                onChange={handleChange}
                input={<OutlinedInput />}
                name="selectlist"
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return localized["select-protocol"];
                  }

                  return selected
                    .map(
                      (id) =>
                        protocols.find((protocol) => protocol.id === id)?.name
                    )
                    .join(", ");
                }}
              >
                <MenuItem
                  sx={{
                    opacity: 1,
                    font: "16px SiemensSans, Arial",
                    fontWeight: "400",
                    lineHeight: "22px",
                  }}
                  disabled
                  value=""
                ></MenuItem>
                {protocols.map((protocol) => (
                  <MenuItem
                    key={protocol.id}
                    value={protocol.id}
                    data-testid={protocol.name}
                    //disabled={protocol.name === 'OPC UA Client2'}
                    sx={{
                      opacity: 1,
                      font: "16px SiemensSans, Arial",
                      fontWeight: "400",
                      lineHeight: "22px",
                    }}
                  >
                    {protocol.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Button
              disableRipple
              variant="contained"
              sx={{
                borderRadius: "24px",
                height: "44px",
                alignItems: "center",
                font: "16px SiemensSans, Arial",
                fontWeight: "400",
                lineHeight: "22px",
              }}
              startIcon={<ArrowDown sx={{ width: "24px", height: "24px" }} />}
              onClick={handleMenuClick}
            >
              <Typography
                variant="h5"
                sx={{
                  textTransform: "none",
                }}
              >
                {localized["create-new"]}
              </Typography>
            </Button>
            <Menu
              id="options-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              PaperProps={{
                sx: {
                  width: "175px",
                  gap: "0px",
                  borderRadius: "4px 0px 0px 0px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #C0C0C0",
                  opacity: 1,
                  marginTop: "8px",
                },
              }}
            >
              {protocols.map((protocol, index) => (
                <MenuItem
                  key={protocol.id}
                  value={protocol.name}
                  onClick={() => handleCreateClick(protocol.id, protocol.name)}
                  data-testid={"singleSelect" + index}
                  sx={{
                    opacity: 1,
                    font: "16px SiemensSans, Arial",
                    fontWeight: "400",
                    lineHeight: "22px",
                  }}
                >
                  {protocol.name}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Stack>
        <CommunicationProtocolsTableView
          configDataList={configurationData}
          setIsRefetchRequired={setIsRefetchConfigsRequired}
        />
      </Stack>
    </>
  );
};

export default CommunicationProtocolTab;
