import { ApolloError, gql, useApolloClient } from "@apollo/client";
import CachedIcon from "@mui/icons-material/Cached";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Divider, List, ListItem, ListItemText } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import localized from "../../../../en.json";
import { DeleteIcon, SaveIcon } from "../../../../theme/Icons/IshIcons";
import { contentBoxStyle } from "../../../../util/CardStyleUtil";
import SoftwareCardUtil from "../../../../util/SoftwareCardUtil";
import { AntSwitch } from "../../../../util/StyleUtil";
import ShowSnackbar from "../../../CustomizedSnackbar/ShowSnackbar";
import ConfirmationDialog from "./ConfirmationDialog";
import { checkIfDataLossMessage, STATUS_DISABLED, STATUS_DISABLING, STATUS_ENABLING, STATUS_RUNNING, STATUS_UNINSTALLING, STATUS_UPDATING } from "../../../../util/ConstantUtils";
import { GetSoftwaresByDeviceId } from "../../../../Models/models";
import { enableDisableApiSuccess, titleCase } from "./EnableAndDisableApiUtil";
import { renderStatusAndVersion } from "./RenderStatusAndVersionOnCard";
import { ListDialogUtil } from "../../../../util/ListDialogUtil";

export const UPDATE_SOFTWARE_ON_DEVICE = gql`
  mutation (
    $deviceId: String!
    $newSoftwareId: Int!
    $currentSoftwareId: Int!
  ) {
    updateSoftwareOnDevice(
      deviceSoftwareUpdateDtoReq: {
        deviceId: $deviceId
        newSoftwareId: $newSoftwareId
        currentSoftwareId: $currentSoftwareId
      }
    ) {
      status
    }
  }
`;
export const UNINSTALL_SOFTWARE = gql`
  mutation ($deviceId: String!, $softwareId: Int!) {
    unInstallSoftwareOnDevice(
      deviceSoftwareDtoReq: { deviceId: $deviceId, softwareId: $softwareId }
    ) {
      status
    }
  }
`;

export const ENABLE_DISABLE_SOFTWARE = gql`
  mutation ($deviceId: String!, $softwareId: Int!, $isDisable: Boolean) {
    updateSoftwareStateOnDevice(
      deviceSoftwareDtoReq: {
        deviceId: $deviceId
        softwareId: $softwareId
        isDisable: $isDisable
      }
    ) {
      status
    }
  }
`;

interface PropTypes {
  installedSoftwareDetail: GetSoftwaresByDeviceId;
  deviceId: string | undefined;
  setIsSoftwareUpdated: Function;
}
const InstalledSoftwareCard = (props: PropTypes) => {
  const { installedSoftwareDetail } = props;
  const [initiallyIsSoftwareDisabled, setInitiallyIsSoftwareDisabled] =
    useState(installedSoftwareDetail?.softwareActionStatus === "disabled");
  const [isSoftwareDisabled, setIsSoftwareDisabled] = useState(
    initiallyIsSoftwareDisabled
  );
  const [initialStatus] = useState(
    initiallyIsSoftwareDisabled ? STATUS_DISABLED : STATUS_RUNNING
  );
  const [status, setStatus] = useState(initialStatus);
  const [loading, setLoading] = React.useState(false);
  const [loadingUninstall, setLoadingUninstall] = React.useState(false);
  const [loadingState, setLoadingState] = React.useState(false);
  const [openVersionListDialog, setOpenVersionListDialog] = useState(false);

  const [deviceStatusChangeDialog, setDeviceStatusChangeDialog] =
    React.useState(false);
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [actionType, setActionType] = React.useState("");
  const [selectedVersion,setSelectedVersion]=useState("")
  const handleConfirmationSoftwareDialog = (actionType: string) => {
    setActionType(actionType);
    setDeviceStatusChangeDialog(true);
  };
  const loadingUpdate =
    loading || installedSoftwareDetail.softwareActionStatus === "updating";
  const uninstalling =
    loadingUninstall ||
    installedSoftwareDetail.softwareActionStatus === "uninstalling";

  const disabledUpdate =
    !installedSoftwareDetail.availableVersions.length ||
    isSoftwareDisabled ||
    loadingUninstall ||
    loadingState ||
    ["uninstalling", "enabling", "disabling"].includes(
      installedSoftwareDetail.softwareActionStatus
    );

  const disableUninstall =
    loadingUninstall ||
    loadingState ||
    loading ||
    ["enabling", "disabling", "updating"].includes(
      installedSoftwareDetail.softwareActionStatus
    );

  const disableEnableDisable =
    loading ||
    loadingUninstall ||
    loadingState ||
    ["updating", "uninstalling"].includes(
      installedSoftwareDetail.softwareActionStatus
    );

  const handleSoftwareStatus = (softwareActionStatus: string) => {
    setLoadingState(true);
    const statusActions: Record<string, () => void> = {
      enabled: () => {
        setStatus(STATUS_RUNNING);
        setLoadingState(false);
      },
      disabled: () => {
        setStatus(STATUS_DISABLED);
        setLoadingState(false);
      },
      default: () => {
        const capitalizedStatus = titleCase(softwareActionStatus) + "...";
        setStatus(capitalizedStatus);
      },
    };

    const statusValue =
      statusActions[softwareActionStatus] || statusActions.default;
    statusValue();

    setInitiallyIsSoftwareDisabled(softwareActionStatus === "disabled");
    setIsSoftwareDisabled(
      ["disabled", "enabling"].includes(softwareActionStatus)
    );
  };

  const handleUpdateSoftware = (
    deviceId: string | undefined,
    newSoftwareId: number,
    currentSoftwareId: number
  ) => {
    setLoading(true);
    setStatus(STATUS_UPDATING);
    setLoadingState(true);
    client
      .mutate({
        mutation: UPDATE_SOFTWARE_ON_DEVICE,
        variables: {
          deviceId: deviceId,
          newSoftwareId: newSoftwareId,
          currentSoftwareId: Number(currentSoftwareId),
        },
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        response.data?.updateSoftwareOnDevice?.status === "done"
          ? ShowSnackbar(
            localized["software-update-success"].replace(
              "{softwareName}",
              installedSoftwareDetail.name
            ),
            true,
            enqueueSnackbar
          )
          : ShowSnackbar(
            localized["software-update-failed"].replace(
              "{softwareName}",
              installedSoftwareDetail.name
            ),
            false,
            enqueueSnackbar
          );
          setLoading(false);
          props.setIsSoftwareUpdated((prevValue: boolean) => !prevValue);
          handleSoftwareStatus(installedSoftwareDetail.softwareActionStatus);
      })
      .catch((error: ApolloError) => {
        if (error?.message.includes("503")) {
          ShowSnackbar(`${localized["software-will-updated-shortly"]}`, true, enqueueSnackbar);
        } else {
          ShowSnackbar(
            localized["software-update-failed"].replace(
              "{softwareName}",
              installedSoftwareDetail.name
            ) + error.message,
            false,
            enqueueSnackbar
          );
          setLoading(false);
          props.setIsSoftwareUpdated((prevValue: boolean) => !prevValue);
          handleSoftwareStatus(installedSoftwareDetail.softwareActionStatus);
        }
      })
  };

  useEffect(() => {
    handleSoftwareStatus(installedSoftwareDetail.softwareActionStatus);
  }, [installedSoftwareDetail.softwareActionStatus]);

  const handleUninstallSoftware = (
    deviceId: string | undefined,
    softwareId: number
  ) => {
    setLoadingUninstall(true);
    setStatus(STATUS_UNINSTALLING);
    setLoadingState(true);
    client
      .mutate({
        mutation: UNINSTALL_SOFTWARE,
        variables: {
          deviceId: deviceId,
          softwareId: Number(softwareId),
        },
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        if (response.data?.unInstallSoftwareOnDevice?.status === "done") {
          ShowSnackbar(
            localized["software-uninstall-success"].replace(
              "{softwareName}",
              installedSoftwareDetail.name
            ),
            true,
            enqueueSnackbar
          );
        } else {
          ShowSnackbar(
            localized["software-uninstall-failed"].replace(
              "{softwareName}",
              installedSoftwareDetail.name
            ),
            false,
            enqueueSnackbar
          );
        }
        props.setIsSoftwareUpdated((prevValue: boolean) => !prevValue);
        setLoadingUninstall(false);
        handleSoftwareStatus(installedSoftwareDetail.softwareActionStatus);
      })
      .catch((error: ApolloError) => {
        if (error?.message.includes("503")) {
          ShowSnackbar(`${localized["software-will-uninstalled-shortly"]}`, true, enqueueSnackbar);
        } else {
          ShowSnackbar(
            localized["software-uninstall-failed"].replace(
              "{softwareName}",
              installedSoftwareDetail.name
            ) +" "+ error.message,
            false,
            enqueueSnackbar
          );
          props.setIsSoftwareUpdated((prevValue: boolean) => !prevValue);
        setLoadingUninstall(false);
        handleSoftwareStatus(installedSoftwareDetail.softwareActionStatus);
        }
      })
  };

  const handleEnableDisable = (
    deviceId: string | undefined,
    softwareId: number,
    isDisable: boolean
  ) => {
    setLoadingState(true);
    setStatus(actionType === "enable" ? STATUS_ENABLING : STATUS_DISABLING);
    client
      .mutate({
        mutation: ENABLE_DISABLE_SOFTWARE,
        variables: {
          deviceId: deviceId,
          softwareId: Number(softwareId),
          isDisable: isDisable,
        },
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        if (
          installedSoftwareDetail.softwareActionStatus === "enabled" ||
          installedSoftwareDetail.softwareActionStatus === "disabled"
        )
          enableDisableApiSuccess(
            response,
            actionType,
            enqueueSnackbar,
            installedSoftwareDetail.name
          );
          const status = response.data?.updateSoftwareStateOnDevice?.status;
          status==="done" ? handleSoftwareStatus(isDisable?"disabled":"enabled"):handleSoftwareStatus(installedSoftwareDetail.softwareActionStatus); 
          props.setIsSoftwareUpdated((prevValue: boolean) => !prevValue);       
      })
      .catch((error: ApolloError) => {
        if (error?.message.includes("503")) {
          ShowSnackbar(`${localized["software-will-be"]} ${actionType}${localized["shortly"]}`, true, enqueueSnackbar);
        } else {
          ShowSnackbar(
            `${localized["failed-to"]} ${actionType} ${localized["software"]} '${installedSoftwareDetail.name}' ` +
            error.message,
            false,
            enqueueSnackbar
          ); 
          setLoadingState(false);
          props.setIsSoftwareUpdated((prevValue: boolean) => !prevValue);
          handleSoftwareStatus(installedSoftwareDetail.softwareActionStatus)
        }
      })
  };
  const getDialogMessage = useCallback(() => {
    const name = installedSoftwareDetail?.name;
    return checkIfDataLossMessage(name, actionType)
      ? `${localized["connection-loss-warning-message"]} ${actionType} ${name} ${localized["connection-loss-device-status-change-message"]}`
      : `To ${actionType} ${localized["device-status-change-message"]}`;
  }, [actionType]);
  const getDialogTitle = useCallback(() => {
    const name = installedSoftwareDetail?.name;
    return checkIfDataLossMessage(name, actionType)
      ? localized["connection-loss-data-not-visible"]
      : localized["device-status-change-header"];
  }, [actionType]);
  const getActionFunction = () => {
    const actionFunctions = {
      uninstall: () =>
        handleUninstallSoftware(props.deviceId, installedSoftwareDetail.id),
      update: () =>
        handleUpdateSoftware(
          props.deviceId,
          Number(selectedVersion),
          installedSoftwareDetail.id
        ),
      disable: () =>
        handleEnableDisable(props.deviceId, installedSoftwareDetail.id, true),
      enable: () =>
        handleEnableDisable(props.deviceId, installedSoftwareDetail.id, false),
    };

    return (actionFunctions as any)[actionType] || (() => { });
  };

  return (
    <Card
      sx={{
        display: "flex",
        padding: "24px",
        paddingRight: "0px",
        maxWidth: 568,
        minWidth: 568,
        maxHeight: 150,
        borderRadius: "8px",
        border: "1px solid #EAEAEA",
        boxShadow: "none",
      }}
    >
      <SoftwareCardUtil>
        <Box display="flex" sx={contentBoxStyle}>
          <Box display="flex" sx={{ gap: "8px" }}>
            <Typography
              variant="h6"
              sx={{ color: "#1B1534", textAlign: "left" }}
            >
              {installedSoftwareDetail?.name}
            </Typography>
          </Box>
          <AntSwitch
            disabled={disableEnableDisable}
            checked={!isSoftwareDisabled}
            onChange={() =>
              handleConfirmationSoftwareDialog(
                isSoftwareDisabled ? "enable" : "disable"
              )
            }
            inputProps={{ "aria-label": "ant design" }}
          />
        </Box>
        <Box
          display="flex"
          sx={{
            alignItems: "center",
            gap: "4px 16px",
            paddingRight: "24px",
            alignSelf: "stretch",
            flexWrap: "wrap",
          }}
        >
          {renderStatusAndVersion(
            status,
            installedSoftwareDetail?.version,
            loadingState
          )}
          <Box display="flex" sx={{ alignItems: "center", gap: "4px" }}>
            <SaveIcon
              sx={{
                width: "16px",
                height: "16px",
                path: {
                  fill: "#8A00E5",
                },
              }}
            />
            <Typography
              variant="overline"
              sx={{
                color: "#393939",
                fontSize: "12px",
                lineHeight: "normal",
                textTransform: "none",
              }}
            >
              {(installedSoftwareDetail?.fileSize / 1024e3).toFixed(3)} MB
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="overline"
          sx={{
            textTransform: "none",
            color: "#393939",
            paddingRight: "24px",
            textAlign: "left",
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
            height: "40px",
          }}
        >
          {installedSoftwareDetail?.description}
        </Typography>
        <CardActions
          sx={{
            display: "flex",
            flexWrap: "wrap",
            padding: uninstalling ? "0px 20px" : "0px 32px",
            alignSelf: "stretch",
            justifyContent: "space-between",
          }}
        >
          <LoadingButton
            loading={uninstalling}
            data-testid="uninstall-software"
            disabled={disableUninstall}
            loadingPosition="start"
            onClick={() => {
              handleConfirmationSoftwareDialog("uninstall");
            }}
            startIcon={<DeleteIcon fill="#da1e28" sx={{ width: "20px", height: "20px" }} />}
            sx={{
              textTransform: "none",
              borderRadius: "24px",
              justifyContent: "center",
              alignItems: "center",
              padding: "8px 16px",
              color: uninstalling ? "#DA1E28 !important" : "#DA1E28",
              ".MuiButton-startIcon": {
                marginRight: uninstalling ? "0px" : "8px",
              },
            }}
          >
            <Typography variant="h5">
              {uninstalling
                ? localized["uninstalling"]
                : localized["uninstall-btn"]}
            </Typography>
          </LoadingButton>
          <LoadingButton
            loading={loadingUpdate}
            disabled={disabledUpdate}
            loadingPosition="start"
            startIcon={<CachedIcon sx={{ width: "24px", height: "24px" }} />}
            variant="outlined"
            onClick={() => {
              setOpenVersionListDialog(true);
              setSelectedVersion("")
            }}
            sx={{
              textTransform: "none",
              width: "145px",
              height: "40px",
              borderRadius: "24px",
              justifyContent: "center",
              alignItems: "center",
              padding: "8px 16px",
              gap: "8px",
              border: loadingUpdate
                ? "1px solid #8A00E5 !important"
                : "1px solid #8A00E5",
              color: loadingUpdate ? "#8A00E5 !important" : "#8A00E5",
            }}
          >
            <Typography variant="h5">
              {loadingUpdate ? STATUS_UPDATING : localized["update"]}
            </Typography>
          </LoadingButton>
        </CardActions>
      </SoftwareCardUtil>

      <ConfirmationDialog
        open={deviceStatusChangeDialog}
        handleClickFn={() => {
          setDeviceStatusChangeDialog(false);
        }}
        actionFunction={getActionFunction()}
        actionType={actionType}
        title={getDialogTitle()}
        message={getDialogMessage()}
      />
        {openVersionListDialog && (
        <ListDialogUtil
          open={openVersionListDialog}
          onClose={() => {
            setOpenVersionListDialog(false);
            setSelectedVersion("")
          } }
          header= {localized["update-software-header"]}
          softwareName={installedSoftwareDetail.name}
          listHeader={localized["select-version"]}
          handleUpdate={()=>{ handleConfirmationSoftwareDialog("update");setOpenVersionListDialog(false);}} 
          disableUpdate={!selectedVersion}         
        >
          <List
            sx={{
              height: "30vh",
              overflowY: "auto",
              paddingBottom: "0px !important",
              paddingTop: "0px !important",
              width: "494px",
              scrollbarWidth: "thin",
              border:"1px solid #C0C0C0",
            
              "&::-webkit-scrollbar": {
                width: "10px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f2f2f2",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#c0c0c0",
                borderRadius: "4px",
              },
            }}
          >
            {installedSoftwareDetail.availableVersions.map((item: any, index: any) => (
              <React.Fragment key={item.id}>
                <ListItem
                  data-testid={`item-${index}`}
                  sx={{
                    height: "56px",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "16px !important",
                    borderLeft:
                        selectedVersion === item.id
                            ? "4px solid #8A00E5"
                            : "4px solid #ffffff",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#EAEAEA",
                      borderLeft:
                          selectedVersion=== item.id
                              ? "4px solid #8A00E5"
                              : "4px solid #EAEAEA",
                    },
                  }}
                  onClick={() => {setSelectedVersion(item.id)}}
                >
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <ListItemText
                      sx={{
                        ".MuiTypography-root": {
                          fontSize: "16px !important",
                          color:"#000"
                        },
                      }}
                      primary={item.version}
                    />
                  </Box>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </ListDialogUtil>
      )}
    </Card>
  );
};

export default InstalledSoftwareCard;
