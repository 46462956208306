import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Box, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import localized from "../../en.json";
import { BreadCrumbContext } from "../../store/breadcrumb-context";
import SoftwareContextProvider from "../../store/software-list-context";
import SoftwareUploadDrawer from "./SoftwareUploadDrawer";
import SoftwareStoreAccordion from "./SoftwareStore/SoftwareStoreAccordion/SoftwareStoreAccordion";
import { SoftwareStoreWarning } from "./SoftwareStoreWarning";
import CustomerContextProvider from "../../store/customerList-context";

const SoftwareManagement = () => {
  const location = useLocation();
  const breadCrumbContext = useContext(BreadCrumbContext);
  const [softwareUploadDrawerOpen, setSoftwareUploadDrawerOpen] =
    useState(false);

  useEffect(() => {
    breadCrumbContext?.setBreadCrumbsArray([
      {
        name: "Software store",
        link: "/software-management/",
      },
    ]);
  }, [breadCrumbContext?.setBreadCrumbsArray, location]);

  const handleDrawerToggle = () => {
    setSoftwareUploadDrawerOpen(!softwareUploadDrawerOpen);
  };

  return (
    <SoftwareContextProvider>
      <CustomerContextProvider>
      <Box height="calc(100vh - 135px)">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mt="24px"
        >
          <Typography variant="h4" ml="32px">
            {localized["software-store-header"]}
          </Typography>
          {!softwareUploadDrawerOpen && (
            <Box
              display="flex"
              flexDirection="row"
              style={{ color: "#8A00E5", cursor: "pointer" }}
              gap="8px"
              mr="50px"
              role="button"
              onClick={handleDrawerToggle}
            >
              <KeyboardDoubleArrowLeftIcon />
              <Typography variant="h5">
                {localized["software-upload"]}
              </Typography>
            </Box>
          )}
        </Box>
        <Stack direction="row" width="100%" justifyContent="space-between" sx={{ backgroundColor: "#f2f2f2" }}>
        <SoftwareStoreAccordion />
          {softwareUploadDrawerOpen && (
            <SoftwareUploadDrawer
              open={softwareUploadDrawerOpen}
              onClose={handleDrawerToggle}
            />
          )}
        </Stack>
      </Box>
      <SoftwareStoreWarning />
      </CustomerContextProvider>
    </SoftwareContextProvider>
  );
};

export default SoftwareManagement;
