import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import localized from "../en.json";

interface PropTypes {
  open: boolean;
  onClose: () => void; 
  header: string;
  softwareName:string;
  listHeader:string
  children: React.ReactNode;
  handleUpdate: Function;
  disableUpdate:boolean;
}
export const ListDialogUtil = ({open,onClose,header,children,softwareName,listHeader,handleUpdate,disableUpdate}:PropTypes) => {
  return (
    <Dialog
    disableEscapeKeyDown
    open={open}
    sx={{
      overflowY: "unset",
      overflowX: "unset",
      ".MuiDialogTitle-root": {
        padding: "0 !important",
      },
      ".MuiDialogContent-root ": {
        padding: "0 !important",
      },
      ".MuiIconButton-root ": {
        padding: "0 !important",
      },
      ".MuiTypography-root ": {
        alignSelf: "center !important",
      },
      ".MuiDialog-paper ": {
        minWidth: "596px !important",
      },
      ".MuiDialogActions-root":{
        padding: "0 !important",
      }
    }}
  >
    <DialogTitle id="customer-access-dialog">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding:"32px"

        }}
      >
        <Typography variant="h4" width="500px" fontWeight="400">
          {header}
        </Typography>

        <IconButton
          data-testid="close-customer-access"
          aria-label="close-icon-button"
          onClick={onClose} 
        >
          <CloseIcon
            sx={{
              color: "#1B1534",
              width: "32px",
              height: "32px",
            }}
          />
        </IconButton>
      </Box>
    </DialogTitle>

    <DialogContent>
      <DialogContentText>
        <Box padding={"32px"}>
          <Typography className="customer-text" variant="h5" color={"#1B1534"}>
            {localized["software"]}
          </Typography>
          <Typography
            variant="body1"
            fontSize="22px"
            textTransform={"none"}
            color={"#1B1534"}
            marginTop={"2px"}
          >
           {softwareName}
          </Typography>
          <Typography variant="h5"
            marginTop={"24px"}
            marginBottom={"8px"}
             color={"#1B1534"}
          >
            {listHeader}
          </Typography>
        
          {children}
        </Box>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
          <Stack width={"100%"}>
            <Box />
            <Stack padding={"32px"}>
              <Button
                data-testid="updateListDialog"
                variant="contained"
                onClick={()=>{handleUpdate()}}
                disabled={disableUpdate} 
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "93%",
                  height: "40px",
                  textTransform: "none",
                  borderRadius: "24px",
                  padding: "8px 16px",
                }}
              >
                <Typography variant="h5">
                  {localized["update"]}
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </DialogActions>    

  </Dialog>
  );
};
