import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { ThemeProvider } from "@emotion/react";
import { SnackbarProvider } from "notistack";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { loginRequest } from "./authConfig";
import SnackbarCloseButton from "./components/CustomizedSnackbar/SnackbarCloseButton";
import { Home } from "./components/Home/Home";
import SignIn from "./components/SignIn/SignIn";
import { theme } from "./theme/theme";

import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
  fromPromise,
} from "@apollo/client";
import { useAuthToken } from "./auth";
import BreadCrumbContextProvider from "./store/breadcrumb-context";

function App() {
  const httpLink = new HttpLink({
    uri: (operation: any) => {
      const api = operation.getContext().apiName;
      if (api === "notification")
        return process.env.REACT_APP_NOTIFICATION_URL || "";
      if (api === "eventlog") return process.env.REACT_APP_EVENTLOG_URL || "";
      return process.env.REACT_APP_BACKEND_URL || "";
    },
    fetchOptions: { timeout: 120000 },
  });
  const { instance, accounts } = useMsal();

  const [, setAuthToken] = useAuthToken();

  const authLink = setContext(async (_, { headers }) => {
    const accessTokenRequest = {
      ...loginRequest,
      account: accounts[0],
    };
    let res: any;
    try {
      res = await instance.acquireTokenSilent(accessTokenRequest);
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${res.accessToken}`,
        },
      };
    } catch (error: any) {
      // do nothing
    }
  });
  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        if (err?.extensions?.code === "UNAUTHENTICATED") {
          return fromPromise(fetchToken())
            .filter((value) => Boolean(value))
            .flatMap((accessToken) => {
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Bearer ${accessToken}`,
                },
              });
              return forward(operation);
            });
        }
      }
    }
  });
  const fetchToken = async () => {
    const accessTokenRequest = {
      ...loginRequest,
      account: accounts[0],
    };
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        setAuthToken(accessTokenResponse.accessToken);
        return accessTokenResponse.accessToken;
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenRedirect(accessTokenRequest)
            .then(function (accessTokenResponse: any) {
              setAuthToken(accessTokenResponse.accessToken);
            })
            .catch(function (accessTokenError) {
              // Acquire token interactive failure
            });
        }
      });
  };
  fetchToken();

  const client = new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <BreadCrumbContextProvider>
          <SnackbarProvider
            classes={{
              variantSuccess: "successAlert",
              variantError: "errorAlert",
            }}
            maxSnack={3}
            action={(snackbarKey) => (
              <SnackbarCloseButton snackbarKey={snackbarKey} />
            )}
          >
            <Pages />
          </SnackbarProvider>
        </BreadCrumbContextProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}
function Pages() {
  return (
    <Routes>
      <Route path="/*" element={<Home />} />
      <Route path="/login" element={<SignIn />} />
    </Routes>
  );
}
export default App;
