import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import CustomerContextProvider from "../../../store/customerList-context";
import CustomerDetails from "./CustomerDetails/CustomerDetails";
import CustomerList from "./CustomerList/CustomerList";

export const CustomerManagement = () => {
  return (
    <Box display="flex" height="calc(100vh - 130px)" width={"100%"}>
      <CustomerContextProvider>
      <CustomerList />
      <Routes>
        <Route path="/:customerId" element={<CustomerDetails />} />
      </Routes>
      </CustomerContextProvider>
    </Box>
  );
};
