import {
    ApolloError,
    gql,
    useApolloClient
} from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    FormDetail,
    FormField
} from "../../../../Models/models";
import localized from "../../../../en.json";
import { BreadCrumbContext } from "../../../../store/breadcrumb-context";
import { CustomerContext } from "../../../../store/customerList-context";
import { truncateText } from "../../../../util/ConstantUtils";
import CreateDialog from "../../../../util/CreateDialog";
import ShowSnackbar from "../../../CustomizedSnackbar/ShowSnackbar";
import AssignEdgeDeviceDialog from "../AssignEdgeDevice/AssignEdgeDeviceDialog";
import NewCustomerSuccessDialog from "./NewCustomerDialog/NewCustomerSuccessDialog";

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($name: String!, $description: String) {
    createCustomer(name: $name, description: $description) {
      id
      name
    }
  }
`;


const CustomerList = () => {

    const navigate = useNavigate();
    const customerId = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const client = useApolloClient();
    const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
    const [open, setOpen] = useState(false);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [customerName, setCustomerName] = useState("");
    const [customerID, setCustomerID] = useState();
    const [submitStatus, setSubmitStatus] = useState(false);
    const [openAssignDialog, setOpenAssignDialog] = useState(false);
    const breadCrumbContext = useContext(BreadCrumbContext);
    const location = useLocation();
    const customerContext = useContext(CustomerContext)
    const handleCloseSuccessDialog = () => {
        setOpenSuccessDialog(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const createCustomer = (createData: any) => {
        setSubmitStatus(false);
        const { name, description } = createData;
        client
            .mutate({
                mutation: CREATE_CUSTOMER,
                variables: {
                    name: name,
                    description: description,
                },
            })
            .then((response) => {
                setSubmitStatus(true);
                setCustomerID(response.data.createCustomer.id);
                setCustomerName(response.data.createCustomer.name);
                ShowSnackbar(
                    localized["customer-creation-success-msg"],
                    true,
                    enqueueSnackbar
                );
                customerContext?.refetchCustomersHandler();
                setOpenSuccessDialog(true);
            })
            .catch((error: ApolloError) => {
                ShowSnackbar(
                    localized["customer-creation-failed-msg"] + " : " + error.message,
                    false,
                    enqueueSnackbar
                );
            });
    };

    const formDetail: FormDetail = {
        title: localized["new-customer-dialog-title"],
        nameLabel: localized["customer-name-label"],
        namePlaceholder: localized["customer-name-placeholder"],
        descriptionLabel: localized["description-label"],
        descriptionPlaceholder: localized["customer-description-placeholder"],
        saveBtnText: localized["create-button"]
    };

    const onSubmitHandler = (data: FormField) => {
        createCustomer(data);
    };

    useEffect(() => {
        setSelectedItemId(customerId["*"] || "");
    }, [customerId]);

    useEffect(() => {
        let breadcrumbArray=[{
            name: "Engineering",
            link: "/engineering/",
          }];
          if (selectedItemId && (customerContext?.customerList ?? []).length > 0) {
            let customerName = customerContext?.customerList.find(customer => customer.id ===Number(selectedItemId))?.name;
            breadcrumbArray.push({ name: customerName ||"",
                link: "/engineering/",})
          }
        breadCrumbContext?.setBreadCrumbsArray(breadcrumbArray);
      }, [breadCrumbContext?.setBreadCrumbsArray, location,selectedItemId,customerContext?.customerList]);

    const handleItemClick = (customerId: string) => {
        navigate(`/engineering/customer/${customerId}`);
        setSelectedItemId(customerId);
    };
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    height: "calc(100vh - 195px)",
                    flexDirection: "column",
                    background: "#ffffff",
                    marginLeft: "32px",
                    marginTop: "24px",
                    borderRadius: "8px",
                }}
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    padding="16px"
                    justifyContent="space-between"
                >
                    <Typography
                        sx={{
                            fontSize: "22px",
                            fontWeight: "700",
                            padding: "8px 16px",
                            paddingLeft: "0px",
                        }}
                    >
                        {localized["customers"]}
                    </Typography>
                    <Button
                        disableRipple
                        data-testid="newCustomer"
                        sx={{
                            alignItems: "center",
                            borderRadius: "24px",
                            padding: "8px 16px",
                        }}
                        onClick={handleClickOpen}
                        startIcon={<AddIcon sx={{ width: "24px", height: "24px" }} />}
                    >
                        <Typography
                            sx={{
                                textTransform: "none",
                                fontSize: "16px",
                                fontWeight: "700",
                            }}
                        >
                            {localized["new"]}
                        </Typography>
                    </Button>
                </Box>
                <Divider />
                <List
                    sx={{
                        height: "100%",
                        overflowY: "auto",
                        paddingBottom: "0px !important",
                        paddingTop: "0px !important",
                        width: "315px",
                        minWidth: "315px",
                        scrollbarWidth: "thin",
                    }}
                >
                    {customerContext?.loading ? (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            height="10vh"
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {" "}
                            {customerContext?.customerList.length === 0 && (
                                <Box margin="24px">
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            color: "#959595",
                                            textTransform: "none",
                                        }}
                                    >
                                        {localized["add-customer"]}
                                    </Typography>
                                </Box>
                            )}
                            {customerContext?.customerList
                                .sort((a: { name: string }, b: { name: string }) =>
                                    a.name.localeCompare(b.name)
                                )
                                .map((item: any, index: any) => (
                                    <React.Fragment key={item.id}>
                                        <ListItem
                                            data-testid={`item-${index}`}
                                            sx={{
                                                height: "56px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                padding: "16px !important",
                                                borderLeft:
                                                    Number(selectedItemId) === item.id
                                                        ? "4px solid #8A00E5"
                                                        : "4px solid #ffffff",
                                                cursor: "pointer",
                                                transition: "background-color 0.3s ease",
                                                "&:hover": {
                                                    backgroundColor: "#EAEAEA",
                                                    borderLeft:
                                                        Number(selectedItemId) === item.id
                                                            ? "4px solid #8A00E5"
                                                            : "4px solid #EAEAEA",
                                                },
                                            }}
                                            onClick={() => handleItemClick(item.id)}
                                        >
                                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                                <ListItemText
                                                    sx={{
                                                        ".MuiTypography-root": {
                                                            fontSize: "16px !important",
                                                        },
                                                    }}
                                                    primary={truncateText(item.name)}
                                                />
                                            </Box>
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                ))}
                        </>
                    )}
                </List>
            </Box>
            <CreateDialog
                open={open}
                handleClose={handleClose}
                formDetail={formDetail}
                onSubmitHandler={onSubmitHandler}
                submitStatus={submitStatus}
            />
            <NewCustomerSuccessDialog
                open={openSuccessDialog}
                handleClose={handleCloseSuccessDialog}
                openAssignDialog={setOpenAssignDialog}
            />

            <AssignEdgeDeviceDialog
                open={openAssignDialog}
                handleClose={() => setOpenAssignDialog(false)}
                customerId={customerID}
                customerName={customerName}
            />
        </>
    );
};

export default CustomerList;
