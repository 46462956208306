import { Box } from "@mui/material";
import {
  GoogleMap,
  InfoWindowF,
  MarkerClusterer,
  useLoadScript,
} from "@react-google-maps/api";
import { useCallback, useEffect, useRef, useState } from "react";
import { Asset, Position } from "../../../Models/models";

import ClusterInfoWindow from "./ClusterInfoWindow";
import "./Map.css";
import MapMarker from "./MapMarker";
type MapPropsType = {
  assets: Asset[];
  onMarkerClickHandler: Function;
};

const Map = (props: MapPropsType) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY || "",
  });
  const [map, setMap] = useState<any>(null);
  const [key, setKey] = useState(0);
  const [infoWindowPosition, setInfoWindowPosition] = useState<Position | null>(
    null
  );
  const [selectedMarkers, setSelectedMarkers] = useState<Asset[]>([]);

  const clusterMarker =
    require("../../../theme/Icons/cluster_marker.svg").default;

  const mapOptions = {
    fullscreenControl: true,
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: false,
  };

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);
  const mapRef = useRef<google.maps.Map | null>(null);
  const intervalRef = useRef<number | null>(null);

  const updateFullscreenButtonText = useCallback(() => {
    const fullscreenControlButton = mapRef.current
      ?.getDiv()
      .querySelector(
        'button[aria-label="Toggle fullscreen view"]'
      ) as HTMLButtonElement | null;

    if (fullscreenControlButton) {
      if (document.fullscreenElement) {
        fullscreenControlButton.title = "Exit full screen mode";
      } else {
        fullscreenControlButton.title = "Enter full screen mode";
      }
    }
  }, []);

  const checkForFullscreenButton = useCallback(() => {
    if (mapRef.current) {
      const fullscreenControlButton = mapRef.current
        .getDiv()
        .querySelector(
          'button[aria-label="Toggle fullscreen view"]'
        ) as HTMLButtonElement | null;

      if (fullscreenControlButton) {
        updateFullscreenButtonText();
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      }
    }
  }, [updateFullscreenButtonText]);

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      setMap(map);
      mapRef.current = map;
      intervalRef.current = window.setInterval(checkForFullscreenButton, 100);
      document.addEventListener("fullscreenchange", updateFullscreenButtonText);
    },
    [checkForFullscreenButton, updateFullscreenButtonText]
  );

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      document.removeEventListener(
        "fullscreenchange",
        updateFullscreenButtonText
      );
    };
  }, [updateFullscreenButtonText]);
  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      props.assets.forEach((asset: Asset) => {
        bounds.extend({
          lat: asset.latitude,
          lng: asset.longitude,
        });
      });
      const mapDiv = map.getDiv();
      map.fitBounds(bounds, {
        top: 0,
        right: 0,
        bottom: 150,
        left: 0,
      });
      const listener = window.google.maps.event.addListener(
        map,
        "zoom_changed",
        () => {
          if (map.getZoom() < 2) {
            map.setZoom(3);
          }
        }
      );

      // Cleanup the listener on component unmount or when map/props.assets change
      return () => {
        window.google.maps.event.removeListener(listener);
      };
    }
  }, [map, props.assets]);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [props.assets]);

  const onMapClick = () => {
    if (infoWindowPosition) {
      setInfoWindowPosition(null);
    }
  };

  useEffect(() => {
    if (map) {
      const clickListener = map.addListener("click", onMapClick);

      return () => {
        window.google.maps.event.removeListener(clickListener);
      };
    }
  }, [map, infoWindowPosition]);
  const markerClusterOptions = {
    averageCenter: true,
    styles: [
      {
        textColor: "#1B1534",
        textSize: 22,
        url: clusterMarker,
        height: 65,
        width: 58,
        textAlign: "center",
        anchorText: [-8, 5] as [number, number],
        anchorIcon: [65, 30] as [number, number],
      },
    ],
  };

  const onClickClusterHandler = (cluster: any) => {
    const clusterMarkers = cluster.getMarkers();
    const areAllMarkersAtSamePosition =
      checkIfMarkersAtSameLocation(clusterMarkers);
    if (areAllMarkersAtSamePosition) {
      const position = {
        lat: clusterMarkers[0].getPosition().lat(),
        lng: clusterMarkers[0].getPosition().lng(),
      };
      setInfoWindowPosition(position);
      const availableAtSame = props.assets.filter(
        (marker: Asset) =>
          Math.round(marker.latitude * 10000) / 10000 ===
          Math.round(clusterMarkers[0].getPosition().lat() * 10000) / 10000 &&
          Math.round(marker.longitude * 10000) / 10000 ===
          Math.round(clusterMarkers[0].getPosition().lng() * 10000) / 10000
      );
      setSelectedMarkers(availableAtSame);
    } else {
      setInfoWindowPosition(null);
      setSelectedMarkers([]);
    }
  };
  const checkIfMarkersAtSameLocation = (clusterMarkers: any[]) => {
    return clusterMarkers.every((marker: any) => {
      return (
        Math.round(marker.getPosition().lat() * 10000) / 10000 ===
        Math.round(clusterMarkers[0].getPosition().lat() * 10000) / 10000 &&
        Math.round(marker.getPosition().lng() * 10000) / 10000 ===
        Math.round(clusterMarkers[0].getPosition().lng() * 10000) / 10000
      );
    });
  };

  return (
    <div key={key}>
      <Box className="map">
        {!isLoaded || loadError ? (
          <h1>Loading...</h1>
        ) : (
          <GoogleMap
            mapContainerClassName="map-container"
            options={mapOptions}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {props.assets.length > 0 && (
              <MarkerClusterer
                onClick={(cluster) => onClickClusterHandler(cluster)}
                options={markerClusterOptions}
              >
                {(clusterer) => (
                  <>
                    <MapMarker
                      assets={props.assets}
                      onMarkerClickHandler={() => { }}
                      clusterer={clusterer}
                    />
                    {infoWindowPosition && (
                      <Box sx={{ marginTop: "50px !important" }}>
                        <InfoWindowF
                          position={infoWindowPosition}
                          onCloseClick={() => setInfoWindowPosition(null)}
                          options={{
                            pixelOffset: new window.google.maps.Size(0, -64),
                          }}
                        >
                          {selectedMarkers.length > 0 && (
                            <ClusterInfoWindow
                              selectedMarkers={selectedMarkers}
                              onMarkerClickHandler={props.onMarkerClickHandler}
                            />
                          )}
                        </InfoWindowF>
                      </Box>
                    )}
                  </>
                )}
              </MarkerClusterer>
            )}
          </GoogleMap>
        )}
      </Box>
    </div>
  );
};

export default Map;
