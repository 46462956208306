import { gql, useApolloClient } from "@apollo/client";
import { useSnackbar } from "notistack";
import React, { createContext, useCallback, useEffect, useState } from "react";
import ShowSnackbar from "../components/CustomizedSnackbar/ShowSnackbar";
import localized from "../en.json";
import { Customers } from "../Models/models";

export const GET_ALL_CUSTOMERS = gql`
  query {
    getCustomers {
      id
      name
    }
  }
`;

interface CustomerContextType {
    customerList: Customers[];
    loading: boolean;
    refetchCustomersHandler: () => void;
}

export const CustomerContext = createContext<CustomerContextType | undefined>(undefined);

export const CustomerContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const client = useApolloClient();
    const { enqueueSnackbar } = useSnackbar();
    const [customerList, setCustomerList] = useState<Customers[]>([]);
    const [loading, setLoading] = useState(false);


    const fetchAllCustomers = useCallback(() => {
      setLoading(true);
      client
        .query({
          query: GET_ALL_CUSTOMERS,
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          setCustomerList(response.data.getCustomers);
        })
        .catch(() => {
          ShowSnackbar(localized["failed-to-fetch-customers"], false, enqueueSnackbar);
        })
        .finally(()=>{
          setLoading(false);
        })
    }, [client, enqueueSnackbar]);
  

    const refetchCustomersHandler = useCallback(() => {
      fetchAllCustomers();
      }, [fetchAllCustomers]);
    
      useEffect(() => {
        fetchAllCustomers();
      }, [fetchAllCustomers]);
    


    return (
        <CustomerContext.Provider value={{ customerList, loading, refetchCustomersHandler: refetchCustomersHandler }}>
            {children}
        </CustomerContext.Provider>
    );
};

export default CustomerContextProvider;