import {
    ApolloError,
    ApolloQueryResult,
    gql,
    useApolloClient,
  } from "@apollo/client";
  import CloseIcon from "@mui/icons-material/Close";
  import SearchIcon from "@mui/icons-material/Search";
  import LoadingButton from "@mui/lab/LoadingButton";
  import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Chip,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";
  import { useSnackbar } from "notistack";
  import React, { useEffect, useRef, useState } from "react";
  import localized from "../../../../en.json";
  import seAppImage from "../../../../theme/Icons/SE_App_Image.svg";
  import {
    cardContentStyle,
    contentBoxStyle,
  } from "../../../../util/CardStyleUtil";
  import ShowSnackbar from "../../../CustomizedSnackbar/ShowSnackbar";
import ConfirmationDialog from "./ConfirmationDialog";
import { CompatibleSoftware } from "../../../../Models/models";
import { InstallIcon } from "../../../../theme/Icons/IshIcons";
  
  interface PropsType {
    deviceId: string | undefined;
    openDialog: boolean;
    closeDialog: Function;
    setIsSoftwareInstalled: Function;
  }
  
  export const GET_COMPATIBLE_SOFTWARES = gql`
    query ($deviceId: ID!) {
      getInstallableSoftwareForDevice(deviceID: $deviceId) {
        id
        name
        description
        version
        softwareActionStatus
        uploadedAt
      }
    }
  `;
  
  export const INSTALL_SOFTWARE_ON_DEVICE = gql`
    mutation ($deviceId: String!, $softwareId: Int!) {
      installSoftwareOnDevice(
        deviceSoftwareDtoReq: { deviceId: $deviceId, softwareId: $softwareId }
      ) {
        status
      }
    }
  `;
  const InstallCompatibleSoftwares = (props: PropsType) => {
    const { deviceId, openDialog, closeDialog } = props;
    const [searchText, setSearchText] = useState("");
    const client = useApolloClient();
    const { enqueueSnackbar } = useSnackbar();
    const [compatibleSoftwares, setCompatibleSoftwares] = useState<
      CompatibleSoftware[]
    >([]);
    const [loading, setLoading] = React.useState(true);
    const [buttonLoading, setButtonLoading] = useState<Record<number, boolean>>({});

    const [filteredCompatibleSoftwares, setFilteredCompatibleSoftwares] =
      useState<CompatibleSoftware[]>([]);
    const [installDialog, setInstallDialog] = React.useState(false);
    const [selectedSoftwareId, setSelectedSoftwareId] = React.useState(0);
    const searchTextRef = useRef<string>("");
    const setSoftwareId = (softwareId: number) => {
      setSelectedSoftwareId(softwareId);
    };
   
    const isInstalling = (software: CompatibleSoftware) =>
      software.softwareActionStatus === "installing";
      const isButtonLoading = (software: CompatibleSoftware) => 
      buttonLoading[software.id] === true;
    
    const isButtonLoadingOrInstalling = (software: CompatibleSoftware) => 
      isButtonLoading(software) || isInstalling(software);
    useEffect(() => {
      searchTextRef.current = searchText;
    }, [searchText]);

    const filterSoftwares=(allAvailableSoftwaresForDevice: any, searchTextRef: React.MutableRefObject<string>)=> {
      return allAvailableSoftwaresForDevice.filter(
        (installSoftwareDetail: { name: string; }) => installSoftwareDetail.name
          .toLowerCase()
          .includes(searchTextRef.current.toLowerCase())
      );
    }
    const getCompatibleSoftwares = () => {
      client
        .query({
          query: GET_COMPATIBLE_SOFTWARES,
          variables: {
            deviceId: deviceId,
          },
          fetchPolicy: "no-cache",
        })
        .then((response: ApolloQueryResult<any>) => {
          let allAvailableSoftwaresForDevice =
            response.data?.getInstallableSoftwareForDevice;
          allAvailableSoftwaresForDevice = allAvailableSoftwaresForDevice.sort(
              (a: { uploadedAt: string }, b: { uploadedAt: string }) =>
                new Date(b.uploadedAt).getTime() - new Date(a.uploadedAt).getTime()
            );
          const filteredInstalledSoftwares =
            filterSoftwares(allAvailableSoftwaresForDevice, searchTextRef);
          setFilteredCompatibleSoftwares(filteredInstalledSoftwares);
          setCompatibleSoftwares(allAvailableSoftwaresForDevice);
        })
        .catch(() => {
          ShowSnackbar(
            localized["compatible-software-failed"],
            false,
            enqueueSnackbar
          );
        })
        .finally(() => {
          setLoading(false);
        });
    };
    useEffect(() => {
      getCompatibleSoftwares();
      const interval = setInterval(() => {
        getCompatibleSoftwares();
      }, 10000);
      return () => {
        clearInterval(interval);
      };
    }, [client, enqueueSnackbar, deviceId]);
    const getDialogMessage = () => {
      return `To install ${localized["device-status-change-message"]}`;
    };
    const installSuccess = (softwareName:string|undefined,isPartial:boolean) => {
      setInstallDialog(false);
      closeDialog();
      props.setIsSoftwareInstalled((prevValue: boolean) => !prevValue);
      getCompatibleSoftwares();
      ShowSnackbar(
        isPartial
          ? localized["software-will-installed-shortly"]?.replace("{softwareName}", softwareName ?? "")
          : localized["software-install-success"]?.replace("{softwareName}", softwareName ?? ""),
        true,
        enqueueSnackbar
      );
    };
    const installFailed = (softwareName:string|undefined,errorMessage: string | null) => {
      ShowSnackbar(localized["software-install-failed"]+` '${softwareName}' `+errorMessage, false, enqueueSnackbar);
    };
    const installSoftware = () => {
      setButtonLoading((prev) => ({ ...prev, [selectedSoftwareId]: true }));
      const selectedSoftware = compatibleSoftwares.find(
        (software) => software.id === selectedSoftwareId
      );
      const softwareName = selectedSoftware?.name
      client
        .mutate({
          mutation: INSTALL_SOFTWARE_ON_DEVICE,
          variables: {
            deviceId: deviceId,
            softwareId: Number(selectedSoftwareId),
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          if (response.data?.installSoftwareOnDevice?.status === "done") {
            installSuccess(softwareName,false);
          } else {
            installFailed(softwareName,null);
          }
        })
        .catch((error: ApolloError) => {
          if (error?.message.includes("503")) {
            installSuccess(softwareName,true);
          } else {
            installFailed(softwareName,error.message);
          }
        })
        .finally(() => {
          setButtonLoading((prev) => ({ ...prev, [selectedSoftwareId]: false })); 
        });
    };
    
    const handleChange = (event: {
      target: { value: React.SetStateAction<string> };
    }) => {
      setSearchText(event.target.value);
      const handleSearch = () => {
        const filteredResultOnSearch = compatibleSoftwares.filter(
          (compatibleSoftware) =>
            compatibleSoftware.name
              .toLowerCase()
              .includes(event.target.value.toString().toLowerCase())
        );
        setFilteredCompatibleSoftwares(filteredResultOnSearch);
      };
  
      handleSearch();
    };
  
    const closeDialogCompatibleSoftwares = () => {
      closeDialog();
      setSearchText("");
      setFilteredCompatibleSoftwares([]);
    };
  
    return (
      <Dialog
        disableEscapeKeyDown
        fullWidth
        open={openDialog}
        sx={{
          overflowY: "unset",
          overflowX: "unset",
          ".MuiDialogTitle-root": {
            padding: "0 !important",
          },
          ".MuiDialogContent-root ": {
            padding: "0 !important",
          },
          ".MuiIconButton-root ": {
            padding: "0 !important",
          },
          ".MuiDialog-paper ": {
            maxWidth: "1112px !important",
            minHeight: "702px !important",
          },
        }}
      >
        <DialogTitle id="software-access-dialog">
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "24px 24px 0px 24px",
            }}
          >
            <IconButton
              data-testid="close-button-dialog"
              aria-label="close-icon-button"
              onClick={closeDialogCompatibleSoftwares}
            >
              <CloseIcon
                sx={{
                  color: "#1B1534",
                  width: "40px",
                  height: "40px",
                }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              borderBottom: "1px solid #C0C0C0",
              padding: "24px",
            }}
          >
            <Box>
              <Typography
                variant="h4"
                sx={{ display: "flex", alignItems: "center" }}
              >
                {localized["compatible-software-header"]}
              </Typography>
            </Box>
            <Box>
              <TextField
                sx={{
                  width: "378px",
                }}
                inputProps={{
                  sx: {
                    "&::placeholder": {
                      color: "#5D596E",
                      opacity: 1,
                    },
                  },
                }}
                id="standard-basic"
                variant="standard"
                placeholder="Search"
                value={searchText}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent
          sx={{
            margin: "16px",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <DialogContentText
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(450px, 1fr))",
              gap: "16px",
            }}
          >
            {loading && (
              <Stack
                width="100%"
                height="300px"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress />
              </Stack>
            )}
            {!compatibleSoftwares.length && !loading && (
              <Stack>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#1B1534",
                    marginTop: "15%",
                    textAlign: "center",
                  }}
                >
                  {localized["no-comaptible-software-present"]}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#1B1534",
                    marginTop: "2%",
                    textAlign: "center",
                  }}
                >
                  {localized["upload-software-instruction"]}
                </Typography>
              </Stack>
            )}

            {filteredCompatibleSoftwares.length > 0 &&
              !loading &&
              filteredCompatibleSoftwares.map((software) => (
                <Card
                  key={software.id}
                  data-testid={`filtered-result-${software.id}`}
                  sx={{
                    display: "flex",
                    padding: "24px",
                    paddingRight: "0px",
                    maxWidth: 568,
                    borderRadius: "4px",
                    border: "1px solid #C0C0C0",
                    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.10) !important",
                  }}
                >
                  <CardMedia
                    sx={{
                      height: 153,
                      width: 153,
                      borderRadius: "10px",
                      boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.13)",
                    }}
                    image={seAppImage}
                    title="ctrlx App"
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                      flex: "1 0 0",
                      justifyContent: "space-between",
                      gap: "24px",
                    }}
                  >
                    <CardContent sx={cardContentStyle}>
                      <Box display="flex" sx={contentBoxStyle}>
                        <Box display="flex" sx={{ gap: "8px" }}>
                          <Typography
                            variant="h6"
                            sx={{ color: "#1B1534", textAlign: "left" }}
                          >
                            {software.name}
                          </Typography>
                          <Chip
                            label={software.version}
                            variant="outlined"
                            sx={{
                              fontSize: "10px",
                              fontWeight: 700,
                              fontFamily: "SiemensSans,Arial",
                              color: "#8A00E5",
                              border: "1px solid #8A00E5",
                              borderRadius: "20px",
                              height: "22px",
                              ".MuiChip-label": { padding: "0px 8px" },
                            }}
                          />
                        </Box>
                      </Box>

                      <Typography
                        variant="overline"
                        sx={{
                          textTransform: "none",
                          color: "#393939",
                          paddingRight: "24px",
                          textAlign: "left",
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 3,
                        }}
                      >
                        {software.description}
                      </Typography>
                    </CardContent>

                    <CardActions
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        padding: "0px 32px",
                        alignSelf: "stretch",
                        justifyContent: "space-between",
                      }}
                    >
                      <LoadingButton
                        data-testid={`install-button-${software.id}`}
                        loading={isButtonLoadingOrInstalling(software)}
                        loadingPosition="start"
                        startIcon={
                          <InstallIcon sx={{ width: "24px", height: "24px" }} />
                        }
                        variant="outlined"
                        onClick={() => {
                          setInstallDialog(true);
                          setSoftwareId(software.id);
                        }}
                        sx={{
                          ml: "auto",
                          gap: "8px",
                          width:
                            !isButtonLoading(software) &&
                            !isInstalling(software)
                              ? "117px"
                              : "140px",
                          height: "40px",
                          textTransform: "none",
                          borderRadius: "24px",
                          border: "1px solid #8A00E5 !important",
                          color: isButtonLoadingOrInstalling(software)
                            ? "#8A00E5 !important"
                            : "#FFF",
                          backgroundColor: isButtonLoadingOrInstalling(software)
                            ? ""
                            : "#8A00E5",
                          "&:hover": {
                            backgroundColor: "#6000A0",
                          },
                        }}
                      >
                        <Typography variant="h5">
                          {isButtonLoadingOrInstalling(software)
                            ? localized["installing"]
                            : localized["install-software"]}
                        </Typography>
                      </LoadingButton>
                    </CardActions>
                  </Box>
                </Card>
              ))}
          </DialogContentText>
        </DialogContent>
        <ConfirmationDialog
          open={installDialog}
          handleClickFn={() => {
            setInstallDialog(false);
          }}
          actionFunction={installSoftware}
          actionType={"install"}
          title={localized["device-status-change-header"]}
          message={getDialogMessage()}
        />
      </Dialog>
    );
  };
  
  export default InstallCompatibleSoftwares;

  